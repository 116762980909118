import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const LabelContainer = styled.div`
  width: 300px;
  background-color: #0b5ed7;
  color: white;
  margin-top: 30px;
  border-radius: 3px;

  &.teste: {
    color: white;
  }
`;
