import React, { useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { InputText } from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';

const Modulo: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [FlgSuperAdm, setFlgSuperAdm] = useState<boolean>(false);
  const [FlgInativo, setFlgInativo] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const formBlank = {
    des_modulo: '',
  };
  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = () => {
    reset(formBlank);
    reset();
    setFlgSuperAdm(false);
    setFlgInativo(false);
    setUpdate(false);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { des_modulo, flg_inativo, flg_requer_superadmin, cod_modulo } = row;

    setValue('des_modulo', des_modulo);
    setFlgSuperAdm(flg_requer_superadmin);
    setFlgInativo(flg_inativo);
    setValue('flg_requer_superadmin', flg_requer_superadmin);
    setValue('flg_inativo', flg_inativo);
    setValue('cod_modulo', cod_modulo);
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataFields: any) => {
    try {
      if (isUpdate) {
        const res = await api.put(`/modulo/${dataFields.cod_modulo}`, {
          ...dataFields,
          num_ordem_menu: 0,
          flg_requer_superadmin: dataFields.flg_requer_superadmin || false,
          flg_inativo: dataFields.flg_inativo || false,
        });
        const { success, message } = res.data;

        if (!success) {
          return toast.warning(message);
        }

        if (success) {
          resetFormData();
          setShowSearch(true);
          return toast.success(message);
        }
      }
      const res = await api.post('/modulo', {
        ...dataFields,
        num_ordem_menu: 0,
        flg_requer_superadmin: dataFields.flg_requer_superadmin || false,
        flg_inativo: dataFields.flg_inativo || false,
      });

      const { success, message } = res.data;
      if (success) {
        toast.success(message);
        setLoader(false);
        return resetFormData();
      }
      return toast.warning(message);
    } catch (e: any) {
      setLoader(false);
      if (e.status === 500) {
        return toast.error(e.data.message);
      }
    }
  });

  const handleDelete = async () => {
    const values = getValues();
    const { cod_modulo } = values;
    const res = await api.delete(`/modulo/${cod_modulo}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    setUpdate(false);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    reset();
    setUpdate(false);
  };

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={183}
          orderModulo={showSearch}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={183}
          title="Cadastro de Módulo"
          codigoRegistro={[
            {
              value: watch('cod_modulo'),
              des_campo: 'Código',
            },
          ]}
          onSave={() => onSave()}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={onNew}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-9">
              <InputText
                label="Descrição"
                name="des_modulo"
                placeholder="Informe a Descrição"
                maxLength={500}
                caseInput="normal"
                register={register}
                isError={!!errors.des_modulo}
                control={control}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <ToggleDefault
                labelText="Requer Superadmin?"
                setChecked={FlgSuperAdm}
                onSwitch={() => {
                  setValue('flg_requer_superadmin', !FlgSuperAdm);
                  setFlgSuperAdm(!FlgSuperAdm);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-1">
              <ToggleDefault
                labelText="
                Inativo?"
                setChecked={FlgInativo}
                onSwitch={() => {
                  setValue('flg_inativo', !FlgInativo);
                  setFlgInativo(!FlgInativo);
                }}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default Modulo;
