import { Button as btn } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;

  h3 {
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .super-app-theme--header {
    background: #8850bf !important;
  }
  #containe-busca-produto {
    padding: 0;
    margin: 0;

    button {
      width: 100%;
    }
  }
`;

export const SeparatorLine = styled.p`
  border-bottom: 1px solid #ededed;
  padding: 0;
  margin: 0;
`;

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const Button = styled(btn)`
  width: 100%;
  background-color: ${(props) => props.color};
  box-shadow: transparent !important;
  border-color: ${(props) => props.color};
  border-radius: 0px 0px 10px 10px;

  &:active {
    background-color: ${(props) => props.focusColor};
  }
  &:active:focus {
    background-color: ${(props) => props.focusColor};
  }
  &:focus {
    background-color: ${(props) => props.focusColor};
    box-shadow: none;
  }
  &:nth-child(2) {
    margin-left: 4px;
  }
`;

export const ButtonCancel = styled.button`
  height: 40px;
  min-width: 85px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  margin: 0 5px;
  background-color: #dc3545;
  &:disabled {
    background-color: #e3bec1;
    cursor: not-allowed;
    &:hover {
      background-color: #e3bec1;
      opacity: 1;
    }
    // eslint-disable-next-line prettier/prettier
  }
  &:hover {
    opacity: 0.7;
  }
  box-shadow: transparent !important;
`;

export const ContainerProduto = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  position: relative;
  width: 100%;
  @media screen and (max-width: 1000px) {
    width: 87%;
  }
  @media screen and (max-width: 765px) {
    width: 100%;
  }
`;
