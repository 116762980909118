import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    grupo_de_etiqueta: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    largura: yup.string().required(),
    altura: yup.string().required(),
    des_foto: yup.string().required(),
  })
  .required();
